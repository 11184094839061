import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "roboto";
`

const Title = styled.h2`
  font-weight: 700;
  margin-bottom: 1em;
  font-family: "roboto";
`

const SubTitle = styled.p`
  padding: 0 1em;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 1em;
`

function CategoryComment() {
  return (
    <Container>
      <Title>プログカテゴリー一覧</Title>
      <SubTitle>
        スキルの向上に役立つ情報を見つけれることができるかもしれません
      </SubTitle>
    </Container>
  )
}

export default CategoryComment
