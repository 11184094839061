import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/layout"
import Main from "../components/index/Main"
import CategoryList from "../components/index/CategoryList"
import CategoryComment from "../components/index/CategoryComment"
import SEO from "../components/layout/seo"

const Container = styled.main`
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="トップページ" article={false} />
      <Container>
        <Main />
        <CategoryComment />
        <CategoryList />
      </Container>
    </Layout>
  )
}
