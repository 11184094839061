import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    max-width: 1000px;
    margin: 0 auto;
    height: 500px;
  }
`
const Title = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  font-family: "Roboto";
  @media (min-width: 768px) {
    font-size: 4em;
  }
`

const SubTitle = styled.h3`
  font-size: 1.2em;
  font-weight: bold;
  font-family: "Roboto";
  margin-top: 0.5em;
  @media (min-width: 768px) {
    font-size: 3em;
  }
`
const InternalLink = styled(Link)`
  text-decoration: none;
  padding: 1em 2em;
  border-radius: 8px;
  margin-top: 2em;
  color: white;
  font-weight: 700;
  background-color: #61dafb;
  margin-bottom: 2em;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
  @media (min-width: 768px) {
    margin-top: 5em;
    margin-bottom: 0;
  }
`

function Main() {
  return (
    <Container>
      <Title>Blog For Front-End Developer</Title>
      <SubTitle>React, GatsbyJS, Next.js...</SubTitle>
      <InternalLink to="/blog">Start</InternalLink>
    </Container>
  )
}

export default Main
